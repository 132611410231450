<template>
  <v-skeleton-loader
    class="mx-auto mt-2"
    type="image"
    style="height:180px;"
    v-if="!certificate"
  ></v-skeleton-loader>
  <v-card class="pa-0 mt-2" v-else-if="issued_urls.length > 0">
    <v-card-title dense
      >Issued URLs
      <v-spacer></v-spacer>
      <v-text-field
        v-model="issued_urls_search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        aria-label="Search"
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="url_table_headers"
      :items="issued_urls"
      class="elevation-1"
      :loading="issued_urls_loading"
      :search="issued_urls_search"
    >
      <template v-slot:item.uri="{ item }">
        <Generic :url="item"></Generic>
      </template>

      <template v-slot:loading>
        <LoadingSVG />
      </template>
    </v-data-table>
  </v-card>
  <v-card v-else class="pa-0 mt-2" disabled>
    <v-card-title class="pa-2 ma-0">No Known Issued URLs</v-card-title>
  </v-card>
</template>

<script>
import LoadingSVG from "../LoadingSVG";
import UserService from "../../store/UserService";
import moment from "moment";
import Generic from "../uris/Generic";
export default {
  name: "IssuedUris",
  components: { LoadingSVG, Generic },
  props: ["certificate"],
  data: function() {
    return {
      issued_urls_loading: true,
      issued_urls_search: "",
      issued_urls: [],
      url_table_headers: [{ text: "URL", sortable: true, value: "uri" }]
    };
  },
  mounted: function() {
    if (this.certificate) {
      UserService.getLocalApi(
        "uris/?issuer_public_key_sha1=" + this.certificate.public_key.sha1
      )
        .then(response => {
          this.issued_urls = response.data.results;
          this.issued_urls_loading = false;
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      this.issuers_loading = false;
    }
  },
  methods: {
    afterNow: function(time) {
      return moment(time).isAfter(moment());
    }
  }
};
</script>

<style scoped></style>
