<template>
  <v-skeleton-loader
    class="mx-auto mt-2"
    type="image"
    style="height:180px;"
    v-if="!certificate"
  ></v-skeleton-loader>
  <v-card class="pa-0 mt-2" v-else-if="issued_certs.length > 0">
    <v-card-title dense
      >Issued Certificates
      <v-spacer></v-spacer>
      <v-text-field
        v-model="issued_certs_search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        aria-label="Search"
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="issued_table_headers"
      :items="issued_certs"
      class="elevation-1"
      :loading="issued_certs_loading"
      :search="issued_certs_search"
    >
      <template v-slot:item.id="{ item }">
        <CertTile :certificate="item"></CertTile>
      </template>

      <template v-slot:item.subject_fqdn.friendly_name="{ item }">
        <span style="font-size:.8em;">{{
          item.subject_fqdn.friendly_name
        }}</span>
      </template>

      <template v-slot:item.valid_to="{ item }">
        <span v-if="afterNow(item.valid_to)">
          {{ item.valid_to | moment("MMM Do YYYY") }}
        </span>
        <span v-else style="color:red;font-weight:bold;">
          {{ item.valid_to | moment("MMM Do YYYY") }}
        </span>
      </template>

      <template v-slot:item.is_revoked="{ item }">
        <v-tooltip v-if="item.is_revoked" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" color="danger" style="float:left"
              >mdi-close-circle</v-icon
            >
          </template>
          <span>Revoked</span>
        </v-tooltip>
        <v-tooltip v-else bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" color="success" style="float:left"
              >mdi-check-circle</v-icon
            >
          </template>
          <span>Not Revoked</span>
        </v-tooltip>

        <v-tooltip v-if="item.is_hosted" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              color="success"
              style="float:left;margin-left:3px;"
              >mdi-earth</v-icon
            >
          </template>
          <span>Currently Hosted</span>
        </v-tooltip>
        <v-tooltip v-else bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              color="#666"
              style="float:left;margin-left:3px;"
              >mdi-earth</v-icon
            >
          </template>
          <span>Not Hosted</span>
        </v-tooltip>
      </template>

      <template v-slot:loading>
        <LoadingSVG></LoadingSVG>
      </template>
    </v-data-table>
  </v-card>
  <v-card v-else class="pa-0 mt-2" disabled>
    <v-card-title class="pa-2 ma-0">No Known Issued Certificates</v-card-title>
  </v-card>
</template>

<script>
import LoadingSVG from "../LoadingSVG";
import UserService from "../../store/UserService";
import CertTile from "../CertTile";
import moment from "moment";
export default {
  name: "IssuedCertificates",
  components: { LoadingSVG, CertTile },
  props: ["certificate"],
  data: function() {
    return {
      issued_certs_loading: true,
      issued_certs_search: "",
      issued_certs: [],
      issued_table_headers: [
        { text: "", sortable: false, width: "104", value: "id" },
        {
          text: "Subject",
          sortable: true,
          value: "subject_fqdn.friendly_name"
        },
        { text: "Valid To", sortable: true, width: 90, value: "valid_to" },
        { text: "Status", sortable: true, width: 60, value: "is_revoked" }
      ]
    };
  },
  mounted: function() {
    if (this.certificate) {
      UserService.getLocalApi(
        "certificates/?issuer_public_key_sha1=" +
          this.certificate.public_key.sha1
      )
        .then(response => {
          this.issued_certs = response.data.results;
          this.issued_certs_loading = false;
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      this.issued_certs_loading = false;
    }
  },
  methods: {
    afterNow: function(time) {
      return moment(time).isAfter(moment());
    }
  }
};
</script>

<style scoped></style>
