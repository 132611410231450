<template>
  <v-skeleton-loader
    class="mx-auto mt-2"
    type="image"
    style="height:180px;"
    v-if="!certificate"
  ></v-skeleton-loader>
  <v-card v-else class="pa-2 mt-0 mb-0">
    <v-card-title class="pa-0 ma-0">Content</v-card-title>
    <table style="width:100%;">
      <tr>
        <td colspan="2">
          <ChipValue
            v-if="certificate.authority_key_id"
            label="AKID"
            :value="certificate.authority_key_id.key_id"
            :search="
              `/search/?authority_key_id=${certificate.authority_key_id.key_id}`
            "
            copy="true"
          ></ChipValue>
          <ChipValue
            label="SKID"
            :value="certificate.subject_key_id.key_id"
            :search="
              `/search/?subject_key_id=${certificate.subject_key_id.key_id}`
            "
            copy="true"
          ></ChipValue>
          <ChipValue
            label="Key Usages"
            :value="certificate.key_usages"
            display="name"
          ></ChipValue>
        </td>
      </tr>
      <tr>
        <td>
          <ChipValue
            label="Public Key Algorithm"
            :value="pka_display"
          ></ChipValue>
        </td>
        <td>
          <ChipValue
            label="Signature Algorithm"
            :value="certificate.signature_algorithm.name"
          ></ChipValue>
        </td>
      </tr>
      <tr>
        <td>
          <ChipValue
            label="Path Length Constraint"
            :value="certificate.path_length_constraint"
          ></ChipValue>
        </td>
        <td>
          <ChipValue
            label="Inhibit Any Policy"
            :value="certificate.inhibit_any"
          ></ChipValue>
        </td>
      </tr>
    </table>
  </v-card>
</template>

<script>
import ChipValue from "../ChipValue";
export default {
  name: "ContentCard",
  props: ["certificate"],
  components: { ChipValue },
  computed: {
    pka_display: function() {
      if (this.certificate) {
        return (
          this.certificate.public_key.algorithm.name.toUpperCase() +
          "-" +
          this.certificate.public_key.bit_size
        );
      } else {
        return null;
      }
    }
  }
};
</script>

<style scoped></style>
