<template>
  <v-skeleton-loader
    class="mx-auto mt-2"
    type="image"
    style="height:180px;"
    v-if="!certificate"
  ></v-skeleton-loader>
  <v-card
    v-else-if="certificate.download_locations.length > 0"
    class="pa-2 mt-2"
  >
    <v-card-title class="pa-0 ma-0">Download Locations</v-card-title>

    <Generic
      v-for="uri in certificate.download_locations"
      :url="uri"
      :key="uri.id"
    ></Generic>
  </v-card>
  <v-card v-else class="pa-2 mt-2" disabled>
    <v-card-title class="pa-0 ma-0">No Known Download Locations</v-card-title>
  </v-card>
</template>

<script>
import Generic from "../uris/Generic";
export default {
  name: "DownloadLocations",
  props: ["certificate"],
  components: { Generic }
};
</script>

<style scoped></style>
