<template>
  <v-skeleton-loader
    class="mx-auto mt-2"
    type="image"
    style="height:180px;"
    v-if="!certificate"
  ></v-skeleton-loader>
  <v-card v-else class="pa-2 mt-2">
    <v-card-title class="pa-0 ma-0">Revocation Status</v-card-title>

    <Generic v-for="sia in certificate.crls" :url="sia" :key="sia.id"></Generic>
  </v-card>
</template>

<script>
import Generic from "../uris/Generic";
export default {
  name: "Revocation",
  props: ["certificate"],
  components: { Generic }
};
</script>

<style scoped></style>
