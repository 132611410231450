<template>
  <v-skeleton-loader
    class="mx-auto mt-2"
    type="image"
    style="height:180px;"
    v-if="!certificate"
  ></v-skeleton-loader>
  <v-card v-else-if="certificate.sias.length > 0" class="pa-2 mt-2">
    <v-card-title class="pa-0 ma-0">
      Subject Information Access (SIA)
    </v-card-title>

    <Generic v-for="uri in certificate.sias" :url="uri" :key="uri.id"></Generic>
  </v-card>
  <v-card v-else class="pa-2 mt-2" disabled>
    <v-card-title class="pa-0 ma-0">
      No Subject Information Access (SIA)
    </v-card-title>
  </v-card>
</template>

<script>
import Generic from "../uris/Generic";
export default {
  name: "Aias",
  props: ["certificate"],
  components: { Generic }
};
</script>

<style scoped></style>
