<template>
  <v-skeleton-loader
    class="mx-auto mt-2"
    type="image"
    style="height:180px;"
    v-if="!certificate"
  ></v-skeleton-loader>
  <v-card v-else class="pa-2 mt-2">
    <v-card-title class="pa-0 ma-0">Certificate Paths</v-card-title>
    <v-row>
      <v-col class="pt-1 pb-0" cols="12" style="font-size:.7em;">
        <v-expansion-panels>
          <v-expansion-panel v-for="(item, i) in policy_paths" :key="i">
            <v-expansion-panel-header
              >{{ item.valid_to }} ({{
                item.policies.length
              }}
              policies)</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <ul>
                <li v-for="p in item.policies" :key="p.id">{{ p }}</li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "Paths",
  props: ["certificate", "policy_paths"]
};
</script>

<style scoped></style>
