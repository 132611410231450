<template>
  <v-row class="ma-1">
    <v-col class="pt-3" cols="12" md="6" lg="8">
      <TitleCard
        :certificate="shared.certificate"
        :not_before="shared.not_before"
        :not_after="shared.not_after"
        :certificate_status="shared.certificate_status"
      ></TitleCard>

      <Paths
        :certificate="shared.certificate"
        :policy_paths="shared.policy_paths"
      ></Paths>

      <v-row>
        <v-col cols="12" lg="7" class="">
          <ContentCard :certificate="shared.certificate"></ContentCard>
        </v-col>
        <v-col cols="12" lg="5" class="">
          <Expiration
            :certificate="shared.certificate"
            :percent_remaining="shared.percent_remaining"
          />
        </v-col>
      </v-row>

      <Revocation :certificate="shared.certificate" />
      <Policies :certificate="shared.certificate" class="mt-1" />
    </v-col>

    <v-col cols="12" md="6" lg="4">
      <Download :certificate="shared.certificate" />
      <DownloadLocations :certificate="shared.certificate" />

      <Aias :certificate="shared.certificate" />
      <Sias :certificate="shared.certificate" />

      <Issuers v-if="shared.certificate" :certificate="shared.certificate" />
      <IssuedCertificates
        v-if="shared.certificate"
        :certificate="shared.certificate"
      />
      <IssuedUris v-if="shared.certificate" :certificate="shared.certificate" />
    </v-col>
  </v-row>
</template>

<script>
import UserService from "../../../store/UserService";
import TitleCard from "../../../components/certificates/TitleCard";
import Paths from "../../../components/certificates/Paths";
import ContentCard from "../../../components/certificates/ContentCard";
import Expiration from "../../../components/certificates/Expiration";
import Revocation from "../../../components/certificates/Revocation";
import Policies from "../../../components/certificates/Policies";
import Download from "../../../components/certificates/Download";
import DownloadLocations from "../../../components/certificates/DownloadLocations";
import Aias from "../../../components/certificates/Aias";
import Sias from "../../../components/certificates/Sias";
import Issuers from "../../../components/certificates/Issuers";
import IssuedCertificates from "../../../components/certificates/IssuedCertificates";
import IssuedUris from "../../../components/certificates/IssuedUris";

export default {
  name: "CertificateAuthority",
  components: {
    IssuedCertificates,
    DownloadLocations,
    Download,
    Revocation,
    ContentCard,
    TitleCard,
    Paths,
    Expiration,
    Policies,
    Aias,
    Sias,
    Issuers,
    IssuedUris
  },
  props: ["shared"],
  data: function() {
    return {
      issued_certs: [],
      issued_certs_loading: true,
      issued_urls: [],
      issued_urls_loading: true
    };
  },
  watch: {
    shared: function() {
      this.loadCertificateDetails();
    }
  },
  methods: {
    loadCertificateDetails: function() {
      let self = this;

      UserService.getLocalApi(
        "certificates/?issuer_public_key_sha1=" +
          self.shared.certificate.public_key.sha1
      ).then(response => {
        self.issued_certs = response.data.results;
        self.issued_certs_loading = false;
      });
      UserService.getLocalApi(
        "uris/?issuer_public_key_sha1=" + self.certificate.public_key.sha1
      ).then(response => {
        self.issued_urls = response.data.results;
        self.issued_urls_loading = false;
      });
    }
  }
};
</script>

<style scoped></style>
