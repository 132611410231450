var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.certificate)?_c('v-skeleton-loader',{staticClass:"mx-auto mt-2",staticStyle:{"height":"180px"},attrs:{"type":"image"}}):(_vm.certificate.issuer_public_key)?_c('v-card',{staticClass:"pa-0 mt-2"},[_c('v-card-title',{attrs:{"dense":""}},[_vm._v("Verified Issuers "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.issuers_search),callback:function ($$v) {_vm.issuers_search=$$v},expression:"issuers_search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.cert_table_headers,"items":_vm.issuers,"loading":_vm.issuers_loading,"search":_vm.issuers_search},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('CertTile',{attrs:{"certificate":item}})]}},{key:"item.issuer_fqdn.friendly_name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":".8em"}},[_vm._v(" "+_vm._s(item.issuer_fqdn.friendly_name)+" ")])]}},{key:"item.valid_to",fn:function(ref){
var item = ref.item;
return [(_vm.afterNow(item.valid_to))?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.valid_to,"MMM Do YYYY"))+" ")]):_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm._f("moment")(item.valid_to,"MMM Do YYYY"))+" ")])]}},{key:"item.is_revoked",fn:function(ref){
var item = ref.item;
return [(item.is_revoked)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"float":"left"},attrs:{"color":"danger"}},'v-icon',attrs,false),on),[_vm._v("mdi-close-circle")])]}}],null,true)},[_c('span',[_vm._v("Revoked")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"float":"left"},attrs:{"color":"success"}},'v-icon',attrs,false),on),[_vm._v("mdi-check-circle")])]}}],null,true)},[_c('span',[_vm._v("Not Revoked")])]),(item.is_hosted)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"float":"left","margin-left":"3px"},attrs:{"color":"success"}},'v-icon',attrs,false),on),[_vm._v("mdi-earth")])]}}],null,true)},[_c('span',[_vm._v("Currently Hosted")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"float":"left","margin-left":"3px"},attrs:{"color":"#666"}},'v-icon',attrs,false),on),[_vm._v("mdi-earth")])]}}],null,true)},[_c('span',[_vm._v("Not Hosted")])])]}},{key:"loading",fn:function(){return [_c('LoadingSVG')]},proxy:true}])})],1):_c('v-card',{staticClass:"pa-0 mt-2",attrs:{"disabled":""}},[_c('v-card-title',{staticClass:"pa-2 ma-0"},[_vm._v("No Known Issuers")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }