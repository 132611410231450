<template>
  <div class="ma-0 pa-0" style="width:100%;" v-if="shared.certificate">
    <template v-if="shared.certificate.is_ca">
      <CertificateAuthority :shared="shared" />
    </template>
  </div>
</template>

<script>
import UserService from "../store/UserService";
import moment from "moment";
import CertificateAuthority from "../components/certificates/types/CertificateAuthority";
function isInt(value) {
  var x;
  if (isNaN(value)) {
    return false;
  }
  x = parseFloat(value);
  return (x | 0) === x;
}

export default {
  name: "Certificate",
  components: { CertificateAuthority },
  data: function() {
    return {
      id: this.$route.params.id,
      shared: {
        certificate: null,
        certificate_status: null,
        not_before: null,
        not_after: null,
        policy_paths: null,
        percent_remaining: null
      }
    };
  },
  computed: {
    loading: {
      get() {
        return this.$store.state.page_loading;
      },
      set(newValue) {
        this.$store.state.page_loading = newValue;
      }
    }
  },
  methods: {
    percentRemaining: function(start, end) {
      if (end > moment.now()) {
        return Math.round(100 * (1 - (moment.now() - start) / (end - start)));
      } else {
        return 0;
      }
    },
    loadCertificateDetails: function() {
      this.loading = true;
      let self = this;

      if (isInt(this.id)) {
        // Get by ID
        UserService.getLocalApi("certificate-details/" + this.id).then(
          response => {
            this.loading = false;
            self.shared.certificate = response.data;

            self.loading = false;
            let now = moment();
            self.shared.not_after = moment(response.data.valid_to).isAfter(now);
            self.shared.not_before = moment(response.data.valid_from).isBefore(
              now
            );
            self.shared.percent_remaining = self.percentRemaining(
              moment(self.shared.certificate.valid_from),
              moment(self.shared.certificate.valid_to)
            );
            if (self.shared.not_after && self.shared.not_before) {
              self.shared.certificate_status = "#4CAF50";
            } else {
              self.shared.certificate_status = "#FF5252";
            }

            if (self.shared.certificate.issuer_public_key) {
              UserService.getLocalApi(
                "certificates/?public_key_sha1=" +
                  self.shared.certificate.issuer_public_key.sha1
              ).then(response => {
                self.shared.issuers = response.data.results;
                self.shared.issuers_loading = false;
              });
            } else {
              self.shared.issuers_loading = false;
              self.shared.issued_certs_loading = false;
              self.shared.issued_urls_loading = false;
            }
            UserService.getLocalApi(
              "certificate-policy-paths/" + self.shared.certificate.id
            ).then(response => {
              self.shared.policy_paths = response.data.valid_to;
            });
          }
        );
      } else if (this.id.length === 40) {
        // Get by SHA 1
        UserService.getLocalApi("certificate-details/?sha1=" + this.id).then(
          response => {
            this.loading = false;
            self.shared.certificate = response.data.results[0];

            self.loading = false;
            let now = moment();
            self.shared.not_after = moment(
              self.shared.certificate.valid_to
            ).isAfter(now);
            self.shared.not_before = moment(
              self.shared.certificate.valid_from
            ).isBefore(now);
            self.shared.percent_remaining = self.percentRemaining(
              moment(self.shared.certificate.valid_from),
              moment(self.shared.certificate.valid_to)
            );
            if (self.shared.not_after && self.shared.not_before) {
              self.shared.certificate_status = "#4CAF50";
            } else {
              self.shared.certificate_status = "#FF5252";
            }

            if (self.shared.certificate.issuer_public_key) {
              UserService.getLocalApi(
                "certificates/?public_key_sha1=" +
                  self.shared.certificate.issuer_public_key.sha1
              ).then(response => {
                self.shared.issuers = response.data.results;
                self.shared.issuers_loading = false;
              });
            } else {
              self.shared.issuers_loading = false;
              self.shared.issued_certs_loading = false;
              self.shared.issued_urls_loading = false;
            }
            UserService.getLocalApi(
              "certificate-policy-paths/" + self.shared.certificate.id
            ).then(response => {
              self.shared.policy_paths = response.data.valid_to;
            });
          }
        );
      } else {
        // Not an int or sha1
        this.loading = false;
        this.$router.push("/404?err=Invalid Certificate Identifier");
      }
    }
  },
  mounted() {
    this.loadCertificateDetails();
  },
  beforeRouteUpdate(to, from, next) {
    this.id = to.params.id;
    this.loading = true;
    this.loadCertificateDetails();
    next();
  }
};
</script>

<style scoped></style>
