<template>
  <v-skeleton-loader
    class="mx-auto mt-2"
    type="image"
    style="height:180px;"
    v-if="!certificate"
  ></v-skeleton-loader>
  <v-card class="pa-0 mt-2" v-else-if="certificate.issuer_public_key">
    <v-card-title dense
      >Verified Issuers
      <v-spacer></v-spacer>
      <v-text-field
        v-model="issuers_search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="cert_table_headers"
      :items="issuers"
      class="elevation-1"
      :loading="issuers_loading"
      :search="issuers_search"
    >
      <template v-slot:item.id="{ item }">
        <CertTile :certificate="item"></CertTile>
      </template>

      <template v-slot:item.issuer_fqdn.friendly_name="{ item }">
        <span style="font-size:.8em;">
          {{ item.issuer_fqdn.friendly_name }}
        </span>
      </template>

      <template v-slot:item.valid_to="{ item }">
        <span v-if="afterNow(item.valid_to)">
          {{ item.valid_to | moment("MMM Do YYYY") }}
        </span>
        <span v-else style="color:red;font-weight:bold;">
          {{ item.valid_to | moment("MMM Do YYYY") }}
        </span>
      </template>

      <template v-slot:item.is_revoked="{ item }">
        <v-tooltip v-if="item.is_revoked" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" color="danger" style="float:left"
              >mdi-close-circle</v-icon
            >
          </template>
          <span>Revoked</span>
        </v-tooltip>
        <v-tooltip v-else bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" color="success" style="float:left"
              >mdi-check-circle</v-icon
            >
          </template>
          <span>Not Revoked</span>
        </v-tooltip>

        <v-tooltip v-if="item.is_hosted" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              color="success"
              style="float:left;margin-left:3px;"
              >mdi-earth</v-icon
            >
          </template>
          <span>Currently Hosted</span>
        </v-tooltip>
        <v-tooltip v-else bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              color="#666"
              style="float:left;margin-left:3px;"
              >mdi-earth</v-icon
            >
          </template>
          <span>Not Hosted</span>
        </v-tooltip>
      </template>

      <template v-slot:loading>
        <LoadingSVG></LoadingSVG>
      </template>
    </v-data-table>
  </v-card>
  <v-card v-else class="pa-0 mt-2" disabled>
    <v-card-title class="pa-2 ma-0">No Known Issuers</v-card-title>
  </v-card>
</template>

<script>
import UserService from "../../store/UserService";
import moment from "moment";
import CertTile from "../CertTile";
import LoadingSVG from "../LoadingSVG";
export default {
  name: "Issuers",
  components: { CertTile, LoadingSVG },
  props: ["certificate"],
  mounted: function() {
    if (this.certificate.issuer_public_key) {
      UserService.getLocalApi(
        "certificates/?public_key_sha1=" +
          this.certificate.issuer_public_key.sha1
      ).then(response => {
        this.issuers = response.data.results;
        this.issuers_loading = false;
      });
    } else {
      this.issuers_loading = false;
    }
  },
  data: function() {
    return {
      issuers_loading: true,
      issuers_search: "",
      issuers: [],
      cert_table_headers: [
        { text: "", sortable: false, width: "104", value: "id" },
        { text: "Issuer", sortable: true, value: "issuer_fqdn.friendly_name" },
        { text: "Valid To", sortable: true, width: 90, value: "valid_to" },
        { text: "Status", sortable: true, width: 60, value: "is_revoked" }
      ]
    };
  },
  methods: {
    afterNow: function(time) {
      return moment(time).isAfter(moment());
    }
  }
};
</script>

<style scoped></style>
