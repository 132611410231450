<template>
  <div>
    <v-skeleton-loader
      class="mx-auto"
      type="image"
      v-if="!certificate"
      style="height:162px;"
    ></v-skeleton-loader>
    <v-alert
      v-else
      :loading="true"
      class="pt-2 mb-0"
      :style="`border-left:5px solid ${certificate_status};`"
    >
      <div class="float-left">
        <span style="font-size:1.25em;font-weight:bold;">{{
          certificate.subject_fqdn.friendly_name
        }}</span>
        <br />
        Issued by {{ certificate.issuer_fqdn.friendly_name }}
        <div class="pt-2" style="font-size:.8em;">
          Certification Authority
          <br />
          Valid from

          <span v-if="not_before">{{
            certificate.valid_from | moment("dddd, MMM Do YYYY")
          }}</span>
          <span v-else style="color:red">{{
            certificate.valid_from | moment("dddd, MMM Do YYYY")
          }}</span>
          to
          <span v-if="not_after">{{
            certificate.valid_to | moment("dddd, MMM Do YYYY")
          }}</span>
          <span v-else style="color:#FF5252;font-weight:bold;">{{
            certificate.valid_to | moment("dddd, MMM Do YYYY")
          }}</span>

          <br />
          <strong>SHA1:</strong> {{ certificate.sha1 }}
          <br />
          <strong>SHA2:</strong> {{ certificate.sha256 }}
        </div>
      </div>
      <div class="float-right pt-3">
        <v-btn style="width:150px;" class="float-right" color="primary" small
          >Add to Compare</v-btn
        >
        <br />
        <v-btn
          style="width:150px;"
          class="mt-2 float-right"
          color="primary"
          small
          >View on Compare</v-btn
        >
      </div>
    </v-alert>
  </div>
</template>

<script>
export default {
  name: "TitleCard",
  props: ["certificate", "not_before", "not_after", "certificate_status"],
  data: function() {
    return {};
  }
};
</script>

<style scoped></style>
