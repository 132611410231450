<template>
  <v-skeleton-loader
    class="mx-auto mt-2"
    type="image"
    style="height:180px;"
    v-if="!certificate"
  ></v-skeleton-loader>
  <v-row v-else>
    <v-col class="pt-1 pb-0" cols="12" style="font-size:.7em;">
      <v-card style="font-size:.9em;" class="mt-2 pa-1">
        <v-simple-table style="width:100%;">
          <template v-slot:default
            ><thead>
              <tr>
                <th class="text-left">Certificate Policies</th>
                <th class="text-left">Mapped To</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="p in policy_set" :key="p.id">
                <td style="font-size:1.2em;">
                  <v-chip
                    class="ma-0 mr-0 pa-2"
                    color="primary lighten-1"
                    label
                    small
                    >{{ p.n }}</v-chip
                  >
                  {{ p.issuer.friendly_name }} ({{ p.issuer.identifier }})

                  <v-btn
                    class="ma-0 mr-0 pa-2"
                    color="primary"
                    label
                    x-small
                    text
                    icon
                    outlined
                    :href="`/search/?policy_oid=${p.issuer.identifier}`"
                  >
                    <v-icon x-small>mdi-magnify</v-icon>
                  </v-btn>
                </td>
                <td style="font-size:1.2em;">
                  <div
                    style="width:100%;margin-bottom:2px;margin-top:2px;"
                    v-for="subject in p.subject"
                    :key="subject.id"
                  >
                    <v-chip
                      class="ma-0 mr-0 pa-2"
                      color="primary lighten-1"
                      label
                      small
                      >{{ subject.n }}</v-chip
                    >
                    {{ subject.policy.friendly_name }} ({{
                      subject.policy.identifier
                    }})

                    <v-btn
                      class="ma-0 mr-0 pa-2"
                      color="primary"
                      label
                      x-small
                      text
                      icon
                      outlined
                      :href="`/search/?policy_oid=${subject.policy.identifier}`"
                    >
                      <v-icon x-small>mdi-magnify</v-icon>
                    </v-btn>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Policies",
  props: ["certificate"],
  data: function() {
    return {
      remote: false,
      loading: false
    };
  },
  computed: {
    policy_set: function() {
      let self = this;
      let result = {};
      let n = 1;

      self.certificate.policies.forEach(item => {
        result[item.id] = {
          issuer: item,
          subject: [],
          n: n
        };
        n++;
      });
      n = 1;

      let already_mapped = [];
      let already = false;
      self.certificate.policy_mappings.forEach(item => {
        if (item.subject_policy in already_mapped) {
          already = true;
        } else {
          already = false;
          already_mapped.push(item.subject_policy);
        }
        if (item.issuer_policy.id in result) {
          result[item.issuer_policy.id].subject.push({
            policy: item.subject_policy,
            already: already,
            n: n
          });
        } else {
          result[item.issuer_policy.id] = {
            issuer: self.certificate.policies[item.issuer_policy],
            subject: [
              {
                policy: item.subject_policy,
                already: already,
                n: n
              }
            ]
          };
        }
        n++;
      });

      return result;
    },
    not_in_policies: function() {
      return null;
    }
  },
  methods: {
    copyText() {
      let textToCopy = this.$refs.textToCopy.$el.querySelector("input");
      textToCopy.select();
      document.execCommand("copy");
    }
  }
};
</script>

<style scoped></style>
