<template>
  <v-skeleton-loader
    class="mx-auto mt-2"
    type="image"
    style="height:180px;"
    v-if="!certificate"
  ></v-skeleton-loader>
  <v-card v-else class="pa-2 mt-0 mb-">
    <v-card-title class="pa-0 ma-0">Expiration</v-card-title>
    <v-row>
      <v-col class="pt-1 pb-2" cols="12" style="font-size:.7em;">
        Percent Life Remaining
        <v-progress-linear
          :value="percent_remaining"
          height="25"
          dark
          color="secondary"
          class="mb-2"
        >
          <strong>{{ percent_remaining }}%</strong>
        </v-progress-linear>

        <ChipValue
          label="Valid From"
          :value="certificate.valid_from"
          copy="true"
        ></ChipValue>
        <ChipValue
          label="Valid To"
          :value="certificate.valid_to"
          copy="true"
        ></ChipValue>
        <div v-if="settings">
          Notification Settings
          <br />
          <ul>
            <li style="list-style-type: none;" v-if="settings.expired">
              <v-icon small color="success">mdi-clock</v-icon> When Expired
            </li>
            <template v-if="settings.percent">
              <li
                style="list-style-type: none;"
                v-if="settings.percent < percent_remaining"
              >
                <v-icon small color="success">mdi-clock</v-icon>
                {{ settings.percent }}% Life Remaining
              </li>
              <li style="list-style-type: none;" v-else>
                <v-icon small color="gray">mdi-check</v-icon>
                <del>{{ settings.percent }}% Life Remaining</del>
              </li>
            </template>

            <li style="list-style-type: none;" v-if="settings.hours">
              <v-icon small color="success">mdi-clock</v-icon>
              {{ settings.hours }}
              Hours Before Expiration
            </li>
          </ul>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import ChipValue from "../ChipValue";
export default {
  name: "Expiration",
  props: ["certificate", "percent_remaining"],
  components: { ChipValue },
  data: function() {
    return {};
  },
  computed: {
    certificate_settings: function() {
      return this.$store.getters.certificate_settings;
    },
    settings: function() {
      let id = this.certificate.id;
      if (this.certificate && this.certificate_settings) {
        let test = this.certificate_settings.filter(function(value) {
          return value.certificate.id === id;
        });
        if (test) {
          return test[0];
        } else {
          return { hours: false, percent: false, expired: false };
        }
      } else {
        return { hours: false, percent: false, expired: false };
      }
    }
  }
};
</script>

<style scoped></style>
